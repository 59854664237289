import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { title, container, link } from ".//PressAndSupporterList.module.css"
import { useTranslation } from "react-i18next"
import { GatsbyImage } from "gatsby-plugin-image"

export default function PressAndSupporterList() {
  const [t] = useTranslation()
  return (
    <StaticQuery
      query={graphql`
        {
          pressList: allContentfulLogo(
            filter: { type: { eq: "Press" }, node_locale: { eq: "de-DE" } }
            sort: { fields: order }
          ) {
            edges {
              node {
                url
                identifier
                logoImage {
                  gatsbyImageData(
                    height: 100
                    width: 180
                    quality: 100
                    resizingBehavior: PAD
                    layout: FIXED
                  )
                }
              }
            }
          }
          sponsorList: allContentfulLogo(
            filter: { type: { eq: "Supporter" }, node_locale: { eq: "de-DE" } }
            sort: { fields: order }
          ) {
            edges {
              node {
                url
                identifier
                logoImage {
                  gatsbyImageData(
                    height: 100
                    width: 180
                    quality: 100
                    resizingBehavior: PAD
                    layout: FIXED
                  )
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <h2 className={title}>{t("Press")}</h2>
          <div className={container}>
            {data.pressList.edges.map(press => (
              <a href={press.node.url} className={link}>
                <GatsbyImage
                  alt={"The logo of" + press.node.identifier}
                  image={press.node.logoImage.gatsbyImageData}
                />
              </a>
            ))}
          </div>
          <h2 className={title}>{t("Sponsors")}</h2>
          <div className={container}>
            {data.sponsorList.edges.map(sponsor => (
              <a href={sponsor.node.url} className={link}>
                <GatsbyImage
                  alt={"The logo of" + sponsor.node.identifier}
                  image={sponsor.node.logoImage.gatsbyImageData}
                />
              </a>
            ))}
          </div>
        </div>
      )}
    />
  )
}
