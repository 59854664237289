import React from "react"
import { container, title } from "../styles/hero-title.module.scss"

const HeroTitle = ({ text }) => {
  return (
    <div className={container}>
      <h1 className={title}>{text}</h1>
    </div>
  )
}

export default HeroTitle
