import { graphql, StaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"
import React from "react"
import {
  container,
  image,
  foregroundContainer,
  title,
  paragraph,
  button,
} from ".//CookieRequest.module.css"
import { GatsbyImage } from "gatsby-plugin-image"
import Cookies from "universal-cookie"

export default function CookieRequest({ identifier, setVisible }) {
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const acceptCookie = () => {
    cookies.set("youtube-video", "true", { path: "/", sameSite: "strict" })
    setVisible(true)
  }
  return (
    <StaticQuery
      query={graphql`
        {
          de_content: allContentfulCookieRequest(
            filter: { node_locale: { eq: "de-DE" } }
          ) {
            edges {
              node {
                ...CookieRequestContent
              }
            }
          }
          ba_content: allContentfulCookieRequest(
            filter: { node_locale: { eq: "bs-BA" } }
          ) {
            edges {
              node {
                ...CookieRequestContent
              }
            }
          }
        }
      `}
      render={data => {
        const content = data[i18n.language + "_content"].edges.find(node => {
          return node.node.identifier === identifier
        }).node
        return (
          <div className={container}>
            <GatsbyImage
              alt=""
              image={content.image.gatsbyImageData}
              className={image}
            />
            <div className={foregroundContainer}>
              <h1 className={title}>{content.title}</h1>
              <p className={paragraph}>{content.description}</p>
              <button className={button} onClick={acceptCookie}>
                {content.buttonText}
              </button>
            </div>
          </div>
        )
      }}
    />
  )
}
