import React from "react"
import { container, image, title, text } from "../styles/feature.module.css"

const Feature = ({ imageFile, titleString, textString }) => {
  return (
    <div className={container}>
      <img className={image} src={`${imageFile}`} alt="Feature" />
      <h2 className={title}>{titleString}</h2>
      <p className={text}>{textString}</p>
    </div>
  )
}

export default Feature
