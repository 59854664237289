import React from "react"
import {
  container,
  avatar,
  content,
  title,
  subtitle,
} from "../styles/avatar.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

const ChatAvatar = ({
  image,
  titleString,
  subtitleString,
  subsubtitleString,
}) => {
  return (
    <div className={container}>
      <div className={avatar}>
        <GatsbyImage
          image={image}
          alt={"A picture of" + titleString}
          imgStyle={{ objectPosition: "50% 50%" }}
          style={{ borderRadius: "50%" }}
        />
      </div>
      <div className={content}>
        <h3 className={title}>{titleString}</h3>
        <p className={subtitle}>{subtitleString}</p>
        <p className={subtitle}>{subsubtitleString}</p>
      </div>
    </div>
  )
}

export default ChatAvatar
