import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { link, title, container } from ".//ContactPersonList.module.css"
import { useTranslation } from "react-i18next"
import Avatar from ".//Avatar.js"

export default function ContactPersonList() {
  const [t] = useTranslation()
  return (
    <StaticQuery
      query={graphql`
        {
          contentfulStudentList(identifier: { eq: "contact-persons" }) {
            ...StudentListWithNamePostitionImage
            studentList {
              linkedInUrl
            }
          }
        }
      `}
      render={data => (
        <div>
          <h2 className={title}>{t("Contact persons")}</h2>
          <div className={container}>
            {data.contentfulStudentList.studentList.map(node => {
              return (
                <a href={node.linkedInUrl} className={link}>
                  <Avatar
                    name={node.name}
                    image={node.image}
                    position={node.position}
                  />
                </a>
              )
            })}
          </div>
        </div>
      )}
    />
  )
}
