import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({
  title,
  description,
  image,
  article,
  author,
  lang,
  keywords,
  useSchema,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    defaultAuthor,
    defaultLang,
    defaultKeywords,
    defaultUseSchema,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
    author: author || defaultAuthor,
    lang: lang || defaultLang,
    keywords: keywords || defaultKeywords,
    useSchema: useSchema || defaultUseSchema,
  }
  return (
    <Helmet
      htmlAttributes={{
        lang: seo.lang,
      }}
      title={seo.title}
      titleTemplate={titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.author && <meta name="twitter:creator" content={seo.author} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <meta name="keywords" content={seo.keywords.join(",")} />
      {seo.useSchema && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://debacode.de/",
            logo: "https://debacode.de/logo_singleT_white.png",
            name: "DeBaCode – Innovative Software – Günstig und Gemeinnützig",
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+49-156-78-343-545",
              contactType: "Customer Support",
            },
          })}
        </script>
      )}
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultAuthor: author
        defaultLang: lang
        defaultKeywords: keywords
        defaultUseSchema: useSchema
      }
    }
  }
`

export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  author: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.array,
  useSchema: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
  author: null,
  lang: null,
  keywords: null,
  useSchema: null,
}
