import React from "react"
import ChatAvatar from "./ChatAvatar"
import {
  projectContainer,
  alternateProjectContainer,
  imageContainer,
  alternateImageContainer,
  chatContainer,
  alternateChatContainer,
  chatBubble,
} from "../styles/project-list.module.css"
import { useTranslation } from "react-i18next"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ProjectList = () => {
  const { i18n } = useTranslation()
  return (
    <StaticQuery
      query={graphql`
        {
          de_content: allContentfulProject(
            filter: { node_locale: { eq: "de-DE" } }
            sort: { fields: order }
          ) {
            ...ProjectContent
          }
          ba_content: allContentfulProject(
            filter: { node_locale: { eq: "bs-BA" } }
            sort: { fields: order }
          ) {
            ...ProjectContent
          }
        }
      `}
      render={data => {
        const edges = data[i18n.language + "_content"].edges

        return (
          <div>
            {edges.map((node, i) => {
              const project = node.node
              const alternate = i % 2 === 1
              return (
                <div
                  className={
                    `${projectContainer}` +
                    (alternate ? ` ${alternateProjectContainer}` : ``)
                  }
                  key={i}
                >
                  <div
                    className={
                      `${imageContainer}` +
                      (alternate ? ` ${alternateImageContainer}` : ``)
                    }
                  >
                    <GatsbyImage
                      alt="Project"
                      image={project.image.gatsbyImageData}
                    />
                  </div>
                  <div
                    className={
                      `${chatContainer}` +
                      (alternate ? ` ${alternateChatContainer}` : ``)
                    }
                  >
                    <div className={chatBubble}>{project.description}</div>
                    <ChatAvatar
                      image={project.referenceImage.gatsbyImageData}
                      titleString={project.referenceName}
                      subtitleString={project.referenceJobtitle}
                      subsubtitleString={project.referenceCompany}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default ProjectList
