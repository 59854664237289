import React from "react"
import {
  avatarName,
  avatarContainer,
  avatarPosition,
} from ".//Avatar.module.css"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Avatar({ name, image, position }) {
  return (
    <div className={avatarContainer}>
      <GatsbyImage
        alt={"A picture of: " + name}
        image={image.gatsbyImageData}
        imgStyle={{
          objectFit: "none",
          objectPosition: "50% 50%",
        }}
        style={{ borderRadius: "50%" }}
      />
      <h3 className={avatarName}>{name}</h3>
      <p className={avatarPosition}>{position}</p>
    </div>
  )
}
